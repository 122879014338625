import { Box, Button, Card, Grid, Stack, Typography } from "@mui/material";
import React from "react";
import moment from "moment";
import { Icon } from "@iconify/react";
import Flag from "./Flag";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import { CardHeader } from "@mui/material";

export default function UpcomingMatch({ match, predict }) {
  // console.log("re rendering upcoming");
  return (
    <Box sx={{ width: "450px" }}>
      <Stack
        sx={{
          background: "linear-gradient(180deg, #1728E1 0%, #2B56F0 50.52%, #0D1FDF 100%)",
          color: "white",
          borderRadius: "15px 15px 0px 0px",
        }}
        direction="row"
        justifyContent="center"
        alignItems="center"
        padding={1}
      >
        {moment(match?.matchDate).format("ddd, DD MMM h:mm a")}
      </Stack>
      <Stack
        direction={"column"}
        paddingY={3}
        paddingX={5}
        spacing={3.5}
        sx={{
          bgcolor: "white",
          borderRadius: "0px 0px 15px 15px",
        }}
      >
        <Stack direction="row" justifyContent="space-between" alignItems="center">
          <Stack
            justifyContent="space-between"
            alignItems="center"
            direction={"column"}
            spacing={1}
          >
            <Card style={{ border: "solid 1px black" }}>
              <Flag flag={match?.firstTeam?.countryFlag} />
            </Card>
            <Typography variant="body2">{match?.firstTeam?.name}</Typography>
          </Stack>

          <Typography variant="body2">Vs</Typography>
          <Stack
            justifyContent="space-between"
            alignItems="center"
            direction={"column"}
            spacing={1}
          >
            <Card style={{ border: "solid 1px black" }}>
              <Flag flag={match?.secondTeam?.countryFlag} />
            </Card>
            <Typography variant="body2">{match?.secondTeam?.name}</Typography>
          </Stack>
        </Stack>
        <Stack direction="row" justifyContent={"center"} alignItems="center" paddingX={7}>
          {/* <Typography  variant="body2">{match?.matchType?.replaceAll("_", " ")}</Typography> */}
        </Stack>
        <Stack direction="row" justifyContent={"center"} alignItems="center" paddingX={7}>
          {match?.pollingStatus === "STARTED" && (
            <Button
              size="small"
              variant="contained"
              color="success"
              onClick={(e) => predict(e, match)}
              sx={{
                width: "150px",
                height: "40px",
                background:
                  "linear-gradient(180deg, rgba(3, 180, 57, 0.9) 0%, rgba(0, 205, 34, 0.9) 48.96%, rgba(3, 176, 61, 0.9) 100%)",
              }}
            >
              Predict Now
            </Button>
          )}
          {match?.pollingStatus !== "STARTED" && (
            <Button
              size="small"
              variant="contained"
              color="success"
              sx={{
                pointerEvents: "none",
                width: "150px",
                height: "40px",
                background:
                  "linear-gradient(180deg, rgba(3, 180, 57, 0.9) 0%, rgba(0, 205, 34, 0.9) 48.96%, rgba(3, 176, 61, 0.9) 100%)",
              }}
              startIcon={<Icon icon="clarity:lock-solid" />}
            >
              Predict Now
            </Button>
          )}
        </Stack>
      </Stack>
    </Box>
  );
}
